/* @import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-angular-navigations/styles/material.css'; */

@import 'bs-stepper/dist/css/bs-stepper.min.css';
@font-face {
  font-family: Open Sans;
  src: url(assets/fonts//open-sans/OpenSans-Regular.ttf) format('truetype');
}

.marginLeftRightCss {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

body {
  background-color: #e5e5e5 !important;
  margin: 0px !important;
  font-family: Open Sans;
}

.whiteBackgroundCss {
  background-color: #ffffff !important;
}

.txtControlCss {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 30px !important;
  font-size: 12px !important;
}

.form-control:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.custom-file-input:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.txtControlCss1 {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  width: 125px;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 30px !important;
}

.txtControlCss4 {
  background: #ffffff !important;
  border: 1px solid #1f83fe !important;
  width: 50px;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 30px !important;
}

.txtControlCss2 {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  width: 125px;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  height: 30px !important;
  width: 80px !important;
}

.txtControlLblCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #012f60 !important;
}

.textareaControls {
  background: #ffffff !important;
  border: 1px solid #8291a5 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
}

.marginCss {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.paddingCss {
  padding: 15px !important;
}

.heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #012f60;
}

.headingSmall {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #012f60;
}

.saveBtnCss {
  background-color: #1f83fe !important;
  border-radius: 3px !important;
  width: 180px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.clearBtnCss {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 180px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}

.clearBtnCss-inline {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 75px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #1f83fe !important;
}

.form-control,
.commonInputBorderCss {
  border-radius: 5px;
}

#searchicon {
  background-color: #ffffff;
  border-color: lightgray;
  color: rgb(55, 147, 223);
  border-left: none;
  box-shadow: none;
}

#searchmargin {
  margin-bottom: 15px;
}

.headerLblCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #012f60;
}

.breadcrumCss {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #012f60;
  opacity: 0.5;
}

.successMessage {
  font-family: Open Sans;
  font-weight: bold;
  color: green;
  font-size: 14px;
}

.errorMessage {
  font-family: Open Sans;
  font-weight: bold;
  color: red;
  font-size: 14px;
}

.table td {
  padding: 5px !important;
}

.table th {
  padding: 5px !important;
}

.table td a {
  cursor: pointer !important;
}

.inlineImages {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  bottom: 5px;
}

.searchImgCss {
  position: absolute;
  margin-left: -30px;
  margin-top: 8px;
}

.searchMarginLeft {
  margin-left: 12px;
}

#inputGroup {
  height: 35px !important;
}

.form-group {
 
  margin-bottom: 10px !important;
}

label {
  margin-bottom: 0px !important;
}

.input-group > .form-control:not(:last-child) {
  
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.text-danger label {
  font-family: Open Sans;
  font-weight: bold;
  color: red !important;
  font-size: 14px;
}

.cursorCss {
  cursor: pointer !important;
}

.paddingLeftCss {
  padding-left: 0px !important;
}

.paddingRightCss {
  padding-right: 2px !important;
}

select[disabled] {
  background-color: #e9ecef !important;
}

.backgroundDivBorderCss {
  border: 1px solid rgba(217, 217, 217, 0.5);
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.02);
  border-radius: 5px;
}

.statusBtnCss {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.statusTerminateBtnCss {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.marginTop10 {
  margin-top: 10px;
}

.txt-color {
  color: #012f60;
}

.commonBorderBottom {
  border-bottom: 1px solid #8291a5 !important;
}

.saveBtnPopupCss {
  background-color: #1f83fe !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.clearBtnPopupCss {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}

.clearBtnPopupCss1 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 75px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}

.clearBtnPopupCss4 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 46px !important;
  height: 35px !important;
  color: #1f83fe !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
}

.clearBtnPopupCss2 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 75px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #1f83fe !important;
}

.clearBtnPopupCss3 {
  border: 2px solid #1f83fe !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  width: 100px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  background-color: #1f83fe !important;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop20 {
  margin-top: 20px;
}

.popupTitleTxt {
  color: #012f60;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 20px;
}

.marginLeftRight15 {
  margin-left: 15px;
  margin-right: 15px;
}

.saveBtnCss1 {
  background-color: #1f83fe !important;
  border-radius: 3px !important;
  width: 120px !important;
  height: 35px !important;
  color: #ffffff !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  margin: 10px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.btn-file {
  color: #25bbfc;
}

/* Added by Akash--- */

.WrapInLine {
  white-space: nowrap;
  vertical-align: middle;
  /* text-align: center; */
}

.scroll {
  border-collapse: collapse;
}

.scroll tr {
  display: flex;
}

.scroll td {
  padding: 3px;
  flex: 1 auto;
  width: 1px;
  word-wrap: break-word;
}

.scroll thead tr:after {
  content: '';
  overflow-y: scroll;
  visibility: hidden;
  height: 0;
}

.scroll thead th {
  flex: 1 auto;
  display: block;
  width: auto !important;
}

.scroll tbody {
  display: block;
  width: 100%;
  overflow-y: auto;
  height: 45vh;
}

/* //---Added by Akash */

.incompleteBtn {
  border: 1px solid #f2994a;
  color: #f2994a;
  font-size: 12px;
}

.searchInput {
  border: solid 1px lightgray !important;
  border-radius: 6px;
  background-color: #fff !important;
  box-shadow: none !important;
}

.customdrpcss {
  position: absolute;
  z-index: 1000;
  right: 20px !important;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.Btnpadding {
  padding: 19px !important;
}

.table-sticky > thead > tr > th,
.table-sticky > thead > tr > td {
  background: white;
  color: black;
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 2;
  /* border-bottom: 3px solid grey !important;
  border-top: 3px solid grey !important; */
  box-shadow: inset 0 0.3px 0 grey, inset 0 -0.3px 0 grey;
}
.table-height {
  height: 320px;
  display: block;
  overflow: scroll;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td {
  border: 1px solid #ddd;
}

.multiselect-dropdown .dropdown-btn {
  display: inline-block;
  border: 1px solid #adadad;
  width: 100%;
  padding: 2px 18px;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1;
  text-align: left;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
}

/* .ng-star-inserted{
  padding-right: 20px !important
} */

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  position: relative;
  right: 0;
  top: 14% !important;
  color: black !important;
  margin-top: 4px !important;
  font-size: 20px;
  font-weight: bold;
  border-style: solid;
  border-width: 0px 0px 0px;
  border-color: transparent !important;
  content: '';
  font-family: FontAwesome !important;
  content: '\f107' !important;
}

.multiselect-dropdown
  .dropdown-btn
  .dropdown-multiselect--active
  .dropdown-multiselect__caret {
  transform: rotateZ(0deg) !important;
}
.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid #337ab7;
  margin-right: 4px;
  background: #337ab7;
  padding: 0 5px;
  color: #fff;
  border-radius: 2px;
  float: left;
  word-break: break-word;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  line-height: 16px;
  display: block;
  position: absolute;
  box-sizing: border-box;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 0;
  padding: 4px 14px !important;
  margin: 0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}

/* .highcharts-root
{
  height: 350px !important;
} */
.highcharts-container .highcharts-credits {
  display: none !important;
}

.ace_print-margin {
  visibility: hidden !important;
  background: transparent !important;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px;
  }
}